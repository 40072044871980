<template>
  <v-container fill-height fluid>
    <v-layout justify-center align-center>
      <v-flex xs12>
        <material-card color="green" title="Cadastrar Produto">
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-text-field v-model="dados.nome" required label="Nome"></v-text-field>
                <v-text-field v-model="dados.sku" required label="SKU"></v-text-field>
                <v-text-field v-model="dados.marca" required label="Marca"></v-text-field>
                <v-text-field v-model="dados.tamanho" required label="Tamanho"></v-text-field>
                <v-text-field v-model="dados.quantidade" required type="number" label="Quantidade"></v-text-field>
                <v-text-field
                  v-model="dados.preco_atacado"
                  required
                  type="text"
                  label="Preço de Compra"
                ></v-text-field>
                <v-text-field
                  v-model="dados.preco_venda"
                  required
                  type="text"
                  label="Preço de Venda"
                ></v-text-field>

                <v-select
                  v-model="select"
                  :items="categorias"
                  :rules="[v => !!v || 'Categoria necessária!']"
                  label="Categoria"
                  v-on:change="getSubCategorias"
                  required
                ></v-select>

                <v-select
                  v-model="select_subcat"
                  :items="subcategorias"
                  :rules="[v => !!v || 'Subcategoria necessária!']"
                  label="SubCategoria"
                  required
                ></v-select>

                <v-flex xs12 text-xs-right>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="success"
                    @click="realizarCadastro('bottom')"
                  >Cadastrar</v-btn>
                </v-flex>
                <v-progress-linear
                  v-if="loading"
                  indeterminate
                  color="#e6c597"
                ></v-progress-linear>
              </v-layout>


              <v-layout>
                <p>Acesso rápido</p>
              </v-layout>
              <v-layout>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4b9275925356902015b', '5e9c585317462115aa67b04a')">Blusa (Feminina)</v-btn>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4b9275925356902015b','5e9c586217462115aa67b04c')">Saia (Feminina)</v-btn>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4b9275925356902015b','5e9c585e17462115aa67b04b')">Calça (Feminina)</v-btn>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4b9275925356902015b','5e9c588617462115aa67b053')">Short (Feminina)</v-btn>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4b9275925356902015b','5e9c586617462115aa67b04d')">Vestido (Feminina)</v-btn>
              </v-layout>
              <v-layout>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4aa275925356902015a', '5e9c58c517462115aa67b057')">Camisa (Masculina)</v-btn>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4aa275925356902015a', '5e9c58d417462115aa67b058')">Bermuda (Masculina)</v-btn>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4aa275925356902015a', '5e9c58dd17462115aa67b059')">Calça (Masculina)</v-btn>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4aa275925356902015a', '5e9c58eb17462115aa67b05c')">Polo (Masculina)</v-btn>
                <v-btn color="success" @click="changeSelecoesCategorias('5d44b4aa275925356902015a', '5e9c58ef17462115aa67b05d')">Regata (Masculina)</v-btn>
              </v-layout>

            </v-container>
          </v-form>
        </material-card>
      </v-flex>

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>Produto cadastrado com sucesso!</div>
        <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>
          {{ error_message }}
        </div>
        <v-icon size="16" @click="snackbarerror = false">mdi-close-circle</v-icon>
      </v-snackbar>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dados: {
      nome: "",
      imagem: "",
      quantidade: 1,
      sku: "",
      marca: "",
      preco_atacado: 0.0,
      preco_venda: 0.0,
      tamanho: ""
    },
    subcategorias: [],
    categorias: [],
    dialog: false,
    error_message: "Verifique os dados e tente novamente\nVerifique a conexão com a internet!",
    snackbar: false,
    loading: false,
    snackbarerror: false,
    snackbarerrorCodigo: false,
    bottom: false,
    select: null,
    select_subcat: null,
    top: true,
    right: false,
    left: false
  }),
  beforeMount() {
    this.getCategorias();
  },
  methods: {
    formatarDecimalValorVenda() {
      // Remove todos os caracteres não numéricos, exceto vírgula e ponto
      this.dados.preco_venda = this.dados.preco_venda.replace(/[^0-9,.]/g, '');

      // Substitui a vírgula por ponto (para que o número seja válido em JavaScript)
      this.dados.preco_venda = this.dados.preco_venda.replace(/,/g, '.');

      // Verifica se o valor é válido como número decimal e atualiza o input
      if (this.dados.preco_venda.match(/^-?\d*(\.\d+)?$/)) {
        this.$refs.inputElement.value = this.dados.preco_venda;
      }
    },
    async getCategorias() {
      let getCategorias = await this.$http.get("/categorias");
      let categories = getCategorias.data.categorias;
      for (let e of categories) {
        e.text = e.nome;
        e.value = e._id;
      }

      this.categorias = categories;
      this.subcategorias = [];
    },
    async getSubCategorias(categoria) {
      try {
        if (categoria) {
          let res_subcategorias = await this.$http.get(
            `/subcategorias/categorias/admin/${categoria}`
          );
          let subcategorias = res_subcategorias.data;
          for (let e of subcategorias) {
            e.text = e.nome;
            e.value = e._id;
          }

          this.subcategorias = subcategorias;
        }
      } catch (err) {
        if(err.error) alert(err.error)
        // console.log(err.error);
      }
    },
    async changeSelecoesCategorias(cat_id, subcat_id){
      this.select = cat_id
      await this.getSubCategorias();
      this.select_subcat = subcat_id
    },
    handleFormadePagamento(e) {
      this.dados.formaPagamento = e;
    },
    showErrorMessage(message_error){
      this.error_message = message_error;
      this.loading = false;
      this.snackbarerror = true;
    },
    async realizarCadastro(...args) {
      this.loading = true;
      let vendedor = JSON.parse(localStorage.getItem("user"));

      if(this.dados.nome == ""){
        this.showErrorMessage("Nome do produto não informado!")
      } else if(this.dados.marca == ""){
        this.showErrorMessage("Marca não informado!")
      } else if(this.dados.quantidade <= 0){
        this.showErrorMessage("Verifique a quantidade do produto! O produto deve ser no mínimo 1")
      } else if(this.dados.tamanho == ""){
        this.showErrorMessage("Tamanho não informado!")
      } else if(this.dados.preco_venda == 0.0){
        this.showErrorMessage("Preço de venda deve ser maior que R$ 0,00!")
      } else {
        var d = new Date();
        var codigo =
          d
            .getFullYear()
            .toString()
            .substring(2, 4) +
          d.getMonth().toString() +
          d.getDate().toString() +
          d.getSeconds().toString() +
          d.getMinutes().toString() +
          d.getHours().toString();

        let categoria = this.select;
        let subcategoria = this.select_subcat;

        let dados = {
          nome: this.dados.nome,
          codigoDeBarras: codigo,
          sku: this.dados.sku,
          preco_atacado: this.dados.preco_atacado,
          preco_venda: this.dados.preco_venda,
          tamanho: this.dados.tamanho,
          marca: this.dados.marca,
          quantidade: this.dados.quantidade,
          categoria: categoria,
          subcategoria: subcategoria,
          user: vendedor,
          loja: "5d4094fa4cff8b21f8c5626a"
        };

        this.top = false;
        this.bottom = false;
        this.left = false;
        this.right = false;

        for (const loc of args) {
          this[loc] = true;
        }

        await this.$http
            .post("/produto", dados)
            .then(res => {
              this.snackbar = true;
              this.loading = false;
            })
            .catch(err => {
              this.loading = false;
              this.snackbarerror = true;
            });
      }
    }
  }
};
</script>
